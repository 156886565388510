<template>
    <loading-animation v-if="loading"/>
    <div v-else class="card bs-5 container">
        <div class="fl-te-c">
            <h4 class="mb-3">Media Details #1</h4>
            <btn size="sm" text="Back" @click="$router.push('/media/')"/>
        </div>
        <s-form @submit="submitClicked" ref="form">
            <validated-input label="Title" name="title" v-model="model.title" :rules="{required : true}"
                             :disabled="loading1"/>
            <div class="row">
                <div class="col">
                    <validated-input label="Order" name="order" v-model="model.order" :rules="{required : true}"
                                     :disabled="loading1"/>
                </div>
                <div class="col">
                    <validated-vue-select label="Status" name="status" v-model="model.status"
                                          :rules="{required : true}" :disabled="loading1" :options="statusOptions"/>
                </div>
            </div>

            <textarea-input label="Description" name="description" v-model="model.description" :disabled="loading1"
                            :rules="{required : true}"/>
            <validated-file-input label="Logo" name="logo" v-model="model.logo" :disabled="loading1"/>
            <validated-file-input label="Image" name="image" v-model="model.image" :disabled="loading1"/>
            <validated-input label="Link" name="link" v-model="model.link" :rules="{required : false}"
                             :disabled="loading1"/>
            <validated-input label="Youtube" name="youtube" v-model="model.youtube" :rules="{required : false}"
                             :disabled="loading1"/>
            <btn size="sm" text="Update" :loading="loading1" loading-text="Updating.."/>

        </s-form>
    </div>
</template>

<script>
import urls from '../../../data/urls';
import axios from 'secure-axios';

export default {
    name : 'MediaDetails',
    data () {
        return {
            loading         : false,
            loading1        : false,
            orderOptionsUrl : urls.admin.media.orderOptionsUrl,
            editUrl         : urls.admin.media.update,
            details         : '',
            id              : this.$route.params.id,
            statusOptions   : [
                { value : 'Published', label : 'Published' },
                { value : 'UnPublished', label : 'UnPublished' }
            ],
            model : {
                id          : this.$route.params.id,
                title       : '',
                order       : '',
                status      : '',
                description : '',
                logo        : '',
                image       : '',
                link        : '',
                youtube     : ''
            }
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods : {
        async submitClicked () {
            const that = this;
            that.loading1 = true;
            const response = await axios.form(urls.admin.media.update, that.model);
            const json = response.data;
            if (json.error === false) {
                that.formSuccess();
                that.loading1 = false;
            } else {
                that.formError(json);
                that.loading1 = false;
            }
            that.loading1 = false;
        },
        async loadDetails () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.admin.media.details, { id : that.id });
            that.details = response.data;
            that.setData();
            that.loading = false;
        },
        setData () {
            const data = this.details.object;
            this.model.title = data.title;
            this.model.order = data.order;
            this.model.status = data.status;
            this.model.description = data.description;
            this.model.logo = data.logo;
            this.model.image = data.image;
            this.model.link = data.link;
            this.model.youtube = data.youtube;
        },
        formSuccess () {
            this.$notify('Updated Successfully', 'Success', {
                type : 'success'
            });
        },
        formError (json) {
            this.$refs.form.setErrors(json.errors);
            this.$notify('Please fix the issue.', 'Error', {
                type : 'danger'
            });
        }
    }
};
</script>

<style scoped>

</style>
